body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-container {
  position: absolute;
  z-index: 1;
}

.header-container img {
  height: 10vh;
  width: auto;
  margin: 10px;
}

.Loader {
  position: absolute;
  z-index: 1;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
}

#block_container {
  text-align: center;
}
#block_container > div {
  display: inline-block;
  vertical-align: middle;
}

.myButton {
	box-shadow:inset 3px 1px 19px -50px #bbdaf7;
	background:linear-gradient(to bottom, #268fff 5%, #e37d39 100%);
	background-color:#268fff;
	border-radius:6px;
	border:1px solid #84bbf3;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:28px 53px;
	text-decoration:none;
	text-shadow:0px 2px 3px #528ecc;
}
.myButton:hover {
	background:linear-gradient(to bottom, #e37d39 5%, #268fff 100%);
	background-color:#e37d39;
}
.myButton:active {
	position:relative;
	top:1px;
}
